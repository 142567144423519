body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
button{
    outline:none!important;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #142DA0;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(120,45,210,1) 100%);
}

body {
    font-family: Orbitron;
}

.main-bg {
    background-image: url(/static/media/main-bg.5f0e2702.png);
    min-height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 40px 70px;
}
.mobiletabSpan{
    font-size: 0.8em;
}
.price-btn {
    width: 100%;
    display: block;
}

.price-btn,
.connect-wallet-btn {
    position: relative;
    display: inline-block;
}

    .price-btn button,
    .connect-wallet-btn button {
        -webkit-transform: skewX(-30deg);
                transform: skewX(-30deg);
    }

    .price-btn button,
    .connect-wallet-btn button {
        background: #E19BFC;
        /* background: linear-gradient(186.22deg, #F3A6FF 13.62%, #B380F5 86.41%); */
        padding: 15px 30px;
        border-radius: 10px 32px 10px 32px;
        position: relative;
        z-index: 9999;
        border: none;
        transition: all 300ms ease-in-out;
        -webkit-transition: all 300ms ease-in-out;
    }

    .connect-wallet-btn:hover:before {
        background: #142DA0;
    }

    .connect-wallet-btn button:hover {
        /* background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%); */
        background: #FBC4E3;
    }

    .price-btn:before,
    .connect-wallet-btn:before {
        position: absolute;
        content: "";
        background: #BC50EF;
        border-radius: 10px 32px 10px 32px;
        height: 100%;
        width: 100%;
        right: -16px;
        bottom: -4px;
        z-index: 99;
        transition: all 300ms ease-in-out;
        -webkit-transform: skewX(-30deg);
                transform: skewX(-30deg);
    }

    .connect-wallet-btn button span {
        -webkit-transform: skew(30deg);
                transform: skew(30deg);
        display: inline-block;
        color: #fff;
        font-weight: 900;
        font-size: 37.3726px;
        line-height: 39px;
    }

    .connect-wallet-btn button img {
        width: 95px;
        margin-right: 7px;
        -webkit-transform: skew(30deg);
                transform: skew(30deg);
    }

.heading-text {
    margin: 0;
    font-weight: 900;
    font-size: 50px;
    line-height: 61px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFF060;
    -webkit-text-stroke: 6px rgba(255, 240, 96, 0.3);
}

.price-btn button span {
    -webkit-transform: skew(30deg);
            transform: skew(30deg);
    display: inline-block;
    color: #fff;
    font-weight: 900;
    font-size: 37.3726px;
    line-height: 39px;
}

.price-btn button {
    background: linear-gradient(200.22deg, #F3A6FF 13.48%, #B380F5 86.55%);
    border-radius: 17px;
    padding: 13px 65px;
    min-width: 100%;
    max-width: 100%;
}

.price-btn:before {
    background: linear-gradient(47.81deg, #B33DEB 13.66%, #DE8FFF 86.36%);
    border-radius: 17px;
    right: -9px;
    bottom: -6px;
}

.about-text {
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #FFF060;
    margin: 0;
}

.community {
    background: linear-gradient(180deg, #F3A6FF 0%, #B380F5 100%);
    border-radius: 20px;
    padding: 12px 55px;
    display: inline-block;
}

    .community h6 {
        font-weight: 900;
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
        background: linear-gradient(158.04deg, #FFE856 12.76%, #FBD241 90.21%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .community > div{
        display:flex;
        justify-content:space-between;
        margin-top:10px;
        align-items:center;
    }

    .price-btn.mint-btn:before {
        background: #BC50EF;
    }

.price-btn.mint-btn:before,
.price-btn.mint-btn {
    -webkit-transition: all 300ms ease-in-out;
}
    .price-btn.mint-btn button span{
        -webkit-transform:skewX(40deg);
                transform:skewX(40deg);
    }
    .price-btn.mint-btn button {
        background: linear-gradient(186.22deg, #F3A6FF 13.62%, #B380F5 86.41%);
    }

    .price-btn.mint-btn button:hover {
        background: linear-gradient(89.32deg, #FAD0C4 2.01%, #FBC2EB 114%);
    }

    .price-btn.mint-btn:hover:before {
        background: #142DA0;
    }

span {
    cursor: pointer;
}

.number {
    display: flex;
}

.minus, .plus {
    position: relative;
    display: inline-block;
    -webkit-transform: skewX(-30deg);
            transform: skewX(-30deg);
}

    .plus button,
    .minus button {
        background: linear-gradient(186.22deg, #F3A6FF 13.62%, #B380F5 86.41%);
        padding: 15px 22px;
        border-radius: 17px;
        position: relative;
        z-index: 9999;
        border: none;
        transition: all 300ms ease-in-out;
        -webkit-transition: all 300ms ease-in-out;
    }

    .minus:before, .plus:before {
        position: absolute;
        content: "";
        background: linear-gradient(17.92deg, #B33DEB 14.33%, #DE8FFF 85.73%);
        border-radius: 17px;
        height: 100%;
        width: 100%;
        right: -8px;
        bottom: -4px;
        z-index: 99;
        transition: all 300ms ease-in-out;
    }

    .plus button span,
    .minus button span {
        -webkit-transform: skew(30deg);
                transform: skew(30deg);
        display: inline-block;
    }

.number .number-input {
    position: relative;
    display: inline-block;
    -webkit-transform: skewX(-30deg);
            transform: skewX(-30deg);
}
.price-btn.mint-btn:before,
.price-btn.mint-btn button {
    -webkit-transform: skewX(-40deg);
            transform: skewX(-40deg);
    border-radius:20px;
}
.number .number-input:before {
    position: absolute;
    content: "";
    background: linear-gradient(186.22deg, #F3A6FF 13.62%, #B380F5 86.41%);
    border-radius: 17px;
    height: 100%;
    width: 100%;
    right: -9px;
    bottom: -5px;
    z-index: 99;
    transition: all 300ms ease-in-out;
}

    .number .number-input span {
        background: linear-gradient(158.04deg, #FFE856 12.76%, #FBD241 90.21%);
        display: inline-block;
        height: 69px;
        font-weight: 900;
        padding: 15px 20px;
        border-radius: 17px;
        position: relative;
        z-index: 9999;
        border: none;
    }

.number input {
    display: inline-block;
    font-size: 44px;
    font-weight: bolder;
    height: 100%;
    width: 100%;
    border: none;
    -webkit-transform: skewX(30deg);
            transform: skewX(30deg);
    /*color: #B380F5;*/
    outline: none !important;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #B23DEB;
    background: linear-gradient(186.22deg, #F3A6FF 13.62%, #B380F5 86.41%);
    /*text-shadow: 3.5px 2px 0px #B23DEB;*/
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#minutes,
#hours,
#seconds{
    /* min-width:86px; */
    display:inline-block;
}
.plus-hover-icon,
.minus-hover-icon {
    display: none;
}

.minus button:hover,
.plus button:hover {
    background: linear-gradient(60deg, #FAD0C4 13.4%, #FBC2EB 86.6%);
}

    .minus button:hover .minus-icon,
    .plus button:hover .plus-icon {
        display: none;
    }

    .minus button:hover .minus-hover-icon,
    .plus button:hover .plus-hover-icon {
        display: inline-block;
    }

.second-one {
    margin: 35px 0;
}
.box-image {
    top: 0%;
    -webkit-animation: box-floating 3.5s infinite;
            animation: box-floating 3.5s infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}
@-webkit-keyframes box-floating{
    0%{
        top:0%;
    }
    100%{
        top:7%;
    }
}
@keyframes box-floating{
    0%{
        top:0%;
    }
    100%{
        top:7%;
    }
}
@media(max-width:991px) {
    .logo {
        width: 100%;
    }

    .main-bg {
        background: url(/static/media/mobile-bg.50ce27c0.png);
        padding: 20px;
        height: unset;
        height: unset;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .connect-wallet-btn button span {
        font-weight: 900;
        font-size: 21.8152px;
    }

    .connect-wallet-btn {
        margin-top: 13px;
    }

        .price-btn, .connect-wallet-btn,
        .price-btn button, .connect-wallet-btn button {
            width: 100%;
        }

            .connect-wallet-btn button {
                padding: 5px 30px;
                border-radius: 10px 15px 10px 15px;
            }

            .connect-wallet-btn:before {
                border-radius: 10px 15px 10px 15px;
                right: -6px;
            }

    .heading-text {
        font-weight: 900;
        font-size: 27px;
        line-height: 31px;
    }

    .box-image {
        max-width: 100% !important;
        margin-right: 10px !important;
    }

    .price-btn button span {
        font-weight: 900;
        font-size: 17.91px;
        line-height: 20px;
    }

    .price-btn button {
        padding: 8px 5px;
        border-radius: 8px;
    }

    .number .number-input span {
        height: 40.87px;
        padding: 0px 10px;
        border-radius: 8px;
    }

    .plus button, .minus button {
        padding: 8px 10px;
        border-radius: 8px;
    }

    .number input {
        font-size: 24px;
        text-shadow: none;
        -webkit-text-stroke-width: 1px;
    }

    .number .number-input:before,
    .minus:before, .plus:before,
    .price-btn:before {
        border-radius: 8px;
        right: -4px;
        bottom: -4px;
    }

    .number span img {
        width: 18px;
    }

    .second-one {
        margin: 15px 0;
    }



    .price-btn.mint-btn button {
        padding: 11px 10px;
        background: linear-gradient(186.22deg, #F3A6FF 13.62%, #B380F5 86.41%);
        border-radius: 13px;
    }

    .price-btn.mint-btn:before {
        border-radius: 13px;
    }

    .community h6 {
        font-size: 18px;
        line-height: 19px;
        margin: 0;
    }

    .community {
        padding: 10px 10px;
        display: flex;
        align-items: center;
        margin-top: 28px;
        justify-content: space-between;
    }

        .community div a:nth-child(2){
            margin:0 15px;
        }
        .community img {
            width: 50px;
        }

    .about-text {
        font-size: 14px;
        line-height: 16px;
        margin-top: 10px;
    }
    .community > div{
        margin:0;
    }
}

@media(max-width:372px) {
    .heading-text {
        font-weight: 900;
        font-size: 22px;
        line-height: 31px;
    }

    .connect-wallet-btn button span {
        font-size: 18px;
    }

    .community {
        flex-wrap: wrap;
        justify-content: center;
    }
}
@media(max-width:575px) {

    
    .box-image {
        top: -10%;
        -webkit-animation: box-floating 3.5s infinite;
                animation: box-floating 3.5s infinite;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        -webkit-animation-direction: alternate;
                animation-direction: alternate;
    }

    @-webkit-keyframes box-floating {
        0% {
            top: -10%;
        }

        100% {
            top: 3%;
        }
    }

    @keyframes box-floating {
        0% {
            top: -10%;
        }

        100% {
            top: 3%;
        }
    }
}

@media(min-width:576px) and (max-width:767px) {
    .box-image {
        max-width: 60% !important;
        margin-right: 10px !important;
    }

    .number-input span,
    .number-input {
        width: 100%;
    }

    .main-bg {
        padding: 50px 30px;
        min-height: 100vh;
    }
}

@media(min-width:768px) and (max-width:1199px) {

    .main-bg {
        padding: 50px 30px;
        min-height: 100vh;
    }

    .connect-wallet-btn button {
        justify-content: center;
    }
    
    .heading-text {
        font-size: 27px;
    }
}

@media(min-width:992px) and (max-width:1499px) {
    .logo {
        width: 100%;
    }
  
    .heading-text {
        font-weight: 900;
        font-size: 32px;
        line-height: 40px;
    }

    .box-image {
        max-width: 100% !important;
        margin-right: 10px !important;
    }

    .price-btn button span {
        font-size: 28px;
    }

    .price-btn button {
        padding: 5px 10px;
        border-radius: 8px;
    }

    .number .number-input span {
        height: 49px;
        padding: 5px 10px;
        border-radius: 8px;
    }

    .plus button, .minus button {
        padding: 13px 20px;
        border-radius: 8px;
    }

    .number input {
        font-size: 28px;
        text-shadow: none;
    }

    .number .number-input:before,
    .minus:before, .plus:before,
    .price-btn:before {
        border-radius: 8px;
        right: -5px;
        bottom: -4px;
    }

    .number span img {
        width: 22px;
    }

    .second-one {
        margin: 25px 0;
    }

    .price-btn.mint-btn button {
        padding: 10px 10px;
    }

    .community h6 {
        font-size: 20px;
        line-height: 19px;
        margin: 0;
    }

    .community {
        padding: 12px 30px;
        margin-top: 17px;
        text-align: center;
    }

        .community img {
            width: 70px;
        }
}

@media(min-width:1500px) {
    .plus button span, .minus button span {
        line-height: 38px;
    }
}
